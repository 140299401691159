import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en.json";
import fr from "./fr.json";
import gr from "./gr.json";

i18next.use(initReactI18next).init({
  lng: "fr", // if you're using a language detector, do not define the lng option (default-language)
  debug: true,
  languages: ["en", "fr", "gr"],
  resources: {
    en: {
      translations: en,
    },
    fr: {
      translations: fr,
    },
    gr: {
      translations: gr,
    },
  },
  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",
  keySeparator: false, // we use content as keys
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
